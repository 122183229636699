/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Text as ThemeText } from '@theme-ui/components';
import { SxStyleProp } from 'theme-ui';

export interface TextProps {
  children?: React.ReactNode;
  variant?: string;
  backgroundImageUrl?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sx?: SxStyleProp;
  [x: string]: any;
}

const Text: React.FC<TextProps> = ({
  children,
  variant = 'default',
  backgroundImageSrc,
  sx,
  ...props
}: TextProps) => {
  const [backgroundClipsupported, setBackgroundClipsupported] = useState(true);

  useEffect(() => {
    setBackgroundClipsupported(
      typeof CSS !== 'undefined' &&
        CSS.supports &&
        (CSS.supports('background-clip') ||
          CSS.supports('-webkit-background-clip', 'text')),
    );
  }, []);

  if (backgroundImageSrc && backgroundClipsupported) {
    return (
      <ThemeText
        variant={variant}
        sx={{
          backgroundImage: `url("${backgroundImageSrc}")`,
          backgroundPosition: 'center',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          backgroundSize: 'cover',
          color: 'transparent',
          ...sx,
        }}
        {...props}>
        {children}
      </ThemeText>
    );
  }
  return (
    <ThemeText variant={variant} sx={sx} {...props}>
      {children}
    </ThemeText>
  );
};

export default Text;

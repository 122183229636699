/* eslint-disable react/jsx-props-no-spreading */
// Header and Content Elements are optional to use

import React, { useEffect, useRef, useState } from 'react';
import Img, { FluidObject } from 'gatsby-image';
import { SxStyleProp } from 'theme-ui';
import { Box, Flex, Heading, BoxOwnProps } from '@theme-ui/components';
import Text from '../../01_atoms/Text/Text';

export interface SectionPropTypes {
  as?: BoxOwnProps['as'];
  backgroundComponent?: React.ReactNode;
  backgroundMaxWidth?: number | number[] | string;
  backgroundMaxHeight?: number | number[] | string;
  backgroundImageFluid?: FluidObject;
  backgroundVideoSrc?: string;
  backgroundColor?: string;
  backgroundOffsetTopColor?: string;
  backgroundOffsetTopSpace?: number;
  backgroundOffsetBottomColor?: string;
  backgroundOffsetBottomSpace?: number;
  backgroundMediaMask?: string;
  px?: number | (number | null)[];
  py?: number | (number | null)[];
  sx?: SxStyleProp;
  contentSx?: SxStyleProp;
  children?: React.ReactNode;
}

export interface SectionHeaderPropTypes {
  title: React.ReactNode;
  titleTextColor?: string;
  subtitle?: string;
  subtitleTextColor?: string;
  sx?: SxStyleProp;
}

export const Header: React.FC<SectionHeaderPropTypes> = ({
  title,
  titleTextColor = 'darkgrey',
  subtitle,
  sx,
}: SectionHeaderPropTypes) => {
  return (
    <Box
      role="heading"
      aria-level={1}
      sx={{
        ...sx,
      }}>
      <Heading
        as="h1"
        variant="section.title"
        aria-label={`${title} ${subtitle || ''}`}
        sx={{
          color: titleTextColor,
          fontWeight: 'bold',
        }}>
        {title}
        {subtitle && (
          <Text
            as="p"
            variant="section.subtitle"
            aria-hidden="true"
            sx={{
              pt: 4,
            }}>
            {subtitle}
          </Text>
        )}
      </Heading>
    </Box>
  );
};

interface BackgroundMediaPropTypes {
  backgroundComponent?: React.ReactNode;
  backgroundImageFluid?: FluidObject;
  backgroundVideoSrc?: string;
  maxWidth?: number | number[] | string;
  maxHeight?: number | number[] | string;
  mask?: string;
}
const BackgroundMedia: React.FC<BackgroundMediaPropTypes> = ({
  backgroundComponent,
  backgroundImageFluid,
  backgroundVideoSrc,
  maxWidth,
  maxHeight,
  mask,
}: BackgroundMediaPropTypes) => {
  // Hide Video if in LowPower Mode
  const videoElmRef = useRef<HTMLVideoElement>(null);
  const [isLowPowerMode, setIsLowPowerMode] = useState(true);

  useEffect(() => {
    const { current } = videoElmRef;
    if (!current) return undefined;
    const handlePlay = (): void => {
      setIsLowPowerMode(false);
    };

    current.addEventListener('play', handlePlay);

    return (): void => {
      current.removeEventListener('play', handlePlay);
    };
  });

  if (!backgroundImageFluid && !backgroundVideoSrc && !backgroundComponent)
    return null;

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        maxWidth,
        maxHeight,
        position: 'relative',
        clipPath: mask,
      }}>
      {Boolean(backgroundComponent) && (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            height: '100%',
            width: '100%',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}>
          {backgroundComponent}
        </Box>
      )}
      {backgroundImageFluid && (
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Img
            fluid={backgroundImageFluid}
            alt=""
            style={{ height: '100%', width: '100%' }}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: '50% 100%',
            }}
          />
        </Box>
      )}
      {backgroundVideoSrc && (
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'opacity 1s ease-out',
            opacity: isLowPowerMode ? '0' : '1',
          }}>
          <video
            ref={videoElmRef}
            autoPlay
            playsInline
            loop
            muted
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: '50% 0',
            }}>
            <source src={backgroundVideoSrc} type="video/mp4" />
          </video>
        </Box>
      )}
    </Box>
  );
};
const Section: React.FC<SectionPropTypes> = ({
  as = 'section',
  backgroundComponent,
  backgroundMaxWidth = 8,
  backgroundMaxHeight = '1500px',
  backgroundColor,
  backgroundImageFluid,
  backgroundVideoSrc,
  backgroundOffsetTopColor,
  backgroundOffsetTopSpace = 0,
  backgroundOffsetBottomColor,
  backgroundOffsetBottomSpace = 0,
  backgroundMediaMask,
  px = [3, 7],
  py = 3,
  sx,
  contentSx,
  children,
  ...props
}: SectionPropTypes) => {
  return (
    <Box
      as={as}
      sx={{
        position: 'relative',
        ...sx,
      }}>
      {Boolean(backgroundOffsetTopSpace) && (
        <Box
          bg={backgroundOffsetTopColor}
          sx={{
            position: 'absolute',
            'z-index': '-1',
            width: '100%',
            top: 0,
            pt: backgroundOffsetTopSpace,
          }}
        />
      )}
      {(backgroundImageFluid ||
        backgroundVideoSrc ||
        backgroundColor ||
        backgroundComponent) && (
        <Flex
          bg={backgroundColor}
          sx={{
            position: 'absolute',
            'z-index': '-2',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            paddingTop: backgroundOffsetTopSpace,
            paddingBottom: backgroundOffsetBottomSpace,
          }}>
          <BackgroundMedia
            backgroundImageFluid={backgroundImageFluid}
            backgroundVideoSrc={backgroundVideoSrc}
            backgroundComponent={backgroundComponent}
            maxWidth={backgroundMaxWidth}
            maxHeight={backgroundMaxHeight}
            mask={backgroundMediaMask}
          />
        </Flex>
      )}
      {Boolean(backgroundOffsetBottomSpace) && (
        <Box
          bg={backgroundOffsetBottomColor}
          sx={{
            position: 'absolute',
            'z-index': '-1',
            width: '100%',
            bottom: 0,
            pb: backgroundOffsetBottomSpace,
          }}
        />
      )}
      <Box {...props} px={px} py={py}>
        <Box
          sx={{
            maxWidth: 5,
            margin: '0 auto',
            // display: 'grid',
            // gridTemplateColumns: '1fr',
            // gridTemplateRows: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            ...contentSx,
          }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Section;

import React from 'react';
import { Image } from '@theme-ui/components';
import { SxStyleProp } from 'theme-ui';
import logoPath from './logo.svg';

export interface LogoProps {
  sx?: SxStyleProp;
}

const Logo: React.FC<LogoProps> = ({ sx }: LogoProps) => (
  <Image
    sx={{
      maxWidth: 'none',
      borderRadius: 'small',
      ...sx,
    }}
    src={logoPath}
    alt="Spielideen Logo"
  />
);

export default Logo;

import React from 'react';
import { FluidObject } from 'gatsby-image';
import { SxStyleProp } from 'theme-ui';
import Section, * as SectionElement from '../../02_molecules/Section/Section';

export interface GenericSectionProps {
  additionalVerticalSpacing?: boolean;
  title?: string;
  subtitle?: string;
  backgroundImageFluid?: FluidObject;
  backgroundColor?: string;
  preTitleComponent?: React.ReactNode;
  children?: React.ReactNode;
  contentSx?: SxStyleProp;
}
const GenericSection: React.FC<GenericSectionProps> = ({
  additionalVerticalSpacing,
  title,
  subtitle,
  children,
  preTitleComponent,
  contentSx,
  backgroundImageFluid,
  backgroundColor,
}: GenericSectionProps) => {
  return (
    <Section
      backgroundImageFluid={backgroundImageFluid}
      backgroundColor={backgroundColor}
      py={additionalVerticalSpacing ? 10 : 3}
      contentSx={{
        alignItems: 'center',
        maxWidth: 5,
        ...contentSx,
      }}>
      {preTitleComponent}
      {title && <SectionElement.Header title={title} subtitle={subtitle} />}
      {children}
    </Section>
  );
};

export default GenericSection;
